import React, { useState } from 'react';
import { useTheme } from '@mui/material';

interface Option {
  id: string;
  text: string;
  isCorrect: boolean;
  feedback: {
    correct: string;
    incorrect: string;
  };
}

interface MultipleChoiceProps {
  question: string;
  options: Option[];
  maxPoints: number; 
  negativePoints: boolean;
  feedback: any;
  onChange: (selectedOptions: string[]) => void;
  isSingleChoice?: boolean;
  isSubmitted?: boolean;
  onSubmit: () => void;
}

const MultipleChoice: React.FC<MultipleChoiceProps> = ({
  question,
  options,
  onChange,
  maxPoints,
  negativePoints,
  feedback,
  isSingleChoice,
  isSubmitted,
  onSubmit,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [points, setPoints] = useState<number>(0);
  const theme = useTheme();
  const totalPoints = options.length;

  const handleChange = (optionId: string) => {
    if (isSingleChoice) {
      setSelectedOptions([optionId]);
    } else {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
      } else {
        setSelectedOptions([...selectedOptions, optionId]);
      }
    }
  };

  const handleCheckAnswers = () => {
    setSubmitted(true)
    let score = totalPoints;
    options.forEach((option) => {
        const isSelected = selectedOptions.includes(option.id);

        if (option.isCorrect && !isSelected) {
            score -= 1;
        } else if (!option.isCorrect && isSelected) {
            score -= 1;
        }
    });

    setPoints(Math.max(0, score));
    onChange(selectedOptions);
    onSubmit();
};


  return (
    <div
      style={{
        padding: '1rem',
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <h3
        style={{
          fontWeight: 'bold',
          marginBottom: '1rem',
          color: theme.palette.text.primary,
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: question }} />
      </h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: submitted ? '1.5rem' : '0.5rem',
        }}
      >
        {options.map((option) => {
          const isSelected = selectedOptions.includes(option.id);
          const showFeedback = submitted;
          const isCorrectAnswer = option.isCorrect;

          return (
            <div
              key={option.id}
              style={{
                transition: 'margin-bottom 0.3s ease',
                marginBottom: showFeedback ? '0rem' : '0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1rem',
                  borderRadius: '0.5rem',
                  border: submitted
                    ? `3px solid ${
                        isCorrectAnswer === isSelected
                          ? theme.palette.success.main
                          : selectedOptions.includes(option.id)
                          ? theme.palette.error.main
                          : '#ccc'
                      }`
                    : '1px solid #ccc',
                  backgroundColor: selectedOptions.includes(option.id)
                    ? '#f0f0f0'
                    : '#fff',
                  cursor: submitted ? 'not-allowed' : 'pointer',
                  transition: 'border 0.3s ease',
                }}
                onClick={() => !submitted && handleChange(option.id)}
              >
                <input
                  type="checkbox"
                  checked={isSelected}
                  readOnly
                  style={{
                    marginRight: '1rem',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: isSelected
                      ? theme.palette.primary.main
                      : '#fff',
                    border: `2px solid ${theme.palette.primary.main}`,
                    cursor: 'pointer',
                    appearance: 'none',
                  }}
                />
                <span style={{ color: theme.palette.text.primary, flex: 1 }}>
                  <div dangerouslySetInnerHTML={{ __html: option.text }} />
                </span>
              </div>

              {/* Feedback unter der Auswahlbox anzeigen */}
              {showFeedback && (
                <div
                  style={{
                    fontSize: '1rem',
                    marginTop: '0.25rem',
                    color:
                      isCorrectAnswer === isSelected
                        ? theme.palette.success.dark
                        : theme.palette.error.dark,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      isCorrectAnswer === isSelected
                        ? option.feedback.correct
                        : option.feedback.incorrect,
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>

      {!submitted && (
          <button
          onClick={handleCheckAnswers}
          style={{
            marginTop: '1rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'rgb(161, 183, 165)',
            color: '#FFFFFF',
            border: 'none',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: '0.85rem',
            boxShadow: theme.shadows[1],
            transition: 'background-color 0.2s ease',
          }}
        >
          Überprüfen
        </button>
      )}

      {submitted && (
        <div style={{ marginTop: '0.5rem', color: theme.palette.text.primary }}>
          <p>
            Erreichte Punkte: {points} von {totalPoints}
          </p>
        </div>
      )}
    </div>
  );
};

export default MultipleChoice;
