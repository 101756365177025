import React, { ChangeEvent, FormEvent, useLayoutEffect, useRef, useState, useEffect } from 'react';
import { Box, Button, IconButton, TextField, Typography, buttonClasses, styled, useTheme } from '@mui/material';
import ImageComponent from 'ui/components/shared/ImageComponent';
import SendIcon from 'assets/icons/send-Filled_1_.png';
import ThumbUpIcon from 'assets/icons/Thumbs Up.png';
import ThumbDownIcon from 'assets/icons/Thumb Down.png';
import ChatBubble from './bubble/ChatBubble';
import { useTranslation } from 'react-i18next';
import ReportModal from './modal/ReportModal';
import { ChatComponentPropType, FeedbackInterface, ParagraphInterface, MultipleChoiceData } from 'entities/interfaces';
import useFontSize from 'hooks/FontSize';
import { SubmitButton } from '../shared/SubmitButton';
import ChatLoader from './ChatLoader';
import BookmarkIcon from 'assets/icons/Bookmark.png';
import BookmarkDarkIcon from 'assets/icons/bookmark-dark.png';
import BookmarkFilledIcon from 'assets/icons/bookmark-filled.png';
import { Loop } from '@mui/icons-material';
import useScreenSize from 'hooks/ScreenSize';
import { MobileWidth } from 'entities/constants';
import MultipleChoice from './MultipleChoice';
import { parseHTMLToJSON } from 'utils/urlDecoder';
import DragAndDrop from './DragAndDrop';

const Wrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
}));

const TypeBox = styled('form')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[800],
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.56rem 0.56rem 0.56rem 2rem',
    borderRadius: '624.9375rem',
    gap: '2rem',
    flexShrink: 0,
    margin: '0.5rem 5rem 1rem 5rem',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
}));

const TitleBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    height: '3.75rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    borderRadius: '0.5rem',
    flexShrink: 0,
    gap: '0.75rem',
}));

const TitleText = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: theme.palette.common.white,
    flexGrow: 1,
    lineHeight: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '100%',
}));

const TitleNum = styled(TitleText)(({ theme }) => ({
    width: '1.98rem',
    height: '1.98rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.common.white}`,
    flexGrow: 0,
}))

const FeedbackBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gap: '1.2rem',
    alignItems: 'center',
    paddingTop: '1rem',
}));

const FeedbackText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bolder',
}));

const FeedbackButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1.6rem',
}));

const FeedbackButton = styled(Button)(({ theme }) => ({
    borderRadius: '2rem',
    textTransform: 'none',
    padding: '0.25rem 3rem',
    fontWeight: 'bolder',
    height: '2.5rem',
}));

const SendButton = styled(Button)(({ theme }) => ({
    borderRadius: '50%',
    width: '3.875rem',
    height: '3.875rem',
    fontSize: '2.8rem',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    
}));

const ChatBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    borderRadius: '1.2rem',
    flexGrow: 1,
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'scroll',
    padding: '0.75rem 1.12rem',
    [theme.breakpoints.up('md')]: {
        '&::-webkit-scrollbar': {
            width: '0.4rem',
            height: '0.4rem',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.dark,
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.grey[700],
        },
        marginRight: '0.1rem',
    },
}));

const ChatComponent = ({
    messagesData,
    onUserInput,
    sendMessage,
    date,
    chapter,
    isInModal,
    typingNotAllowed,
    isHome,
    handleFeedback,
    handleNextChapter,
    bookmarkChapter,
    reportAllowed,
    sendDisabled,
    isLoading,
    handleNext,
    clearProgress,
    reportParagraph,
    typingAllowed,
    onSubmit
    
}: ChatComponentPropType) => {
    const fontSize = useFontSize();
    const { width } = useScreenSize();
    const theme = useTheme();
    const { t } = useTranslation();
    const [reportParagraphId, setReportParagraphId] = useState('');
    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [messageText, setMessageText] = useState('');
    const containerRef = useRef<HTMLDivElement>(null);
    const [apiRequestData, setApiRequestData] = useState<{ context: string; prompt: string; userInput: string } | null>(null);
    const [isSubmitted, setIsSubmitted] = useState(false)



    useLayoutEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messagesData, isLoading]);


    const formatDate = (date: Date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const day = days[date.getDay()];
        const month = months[date.getMonth()];
        const dateNumber = date.getDate();
        const suffix =
            dateNumber === 1 || dateNumber === 11 || dateNumber === 21 || dateNumber === 31
                ? 'st'
                : dateNumber === 2 || dateNumber === 12 || dateNumber === 22
                ? 'nd'
                : 'th';

                return `${day}, ${month} ${dateNumber}${suffix}`;
    };

    function isFeedbackInterface(obj: any): obj is FeedbackInterface {
        return 'feedback' in obj;
    }


    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (messageText.trim()) {
          onUserInput(messageText.trim()); 
          setMessageText(''); 
      }
  };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessageText(event.target.value);
    };

    function isParagraphInterface(message: FeedbackInterface | ParagraphInterface): message is ParagraphInterface {
      return 'type' in message && 'content' in message && typeof message.content === 'object';
  }

  function hasContent(message: FeedbackInterface | ParagraphInterface): message is ParagraphInterface {
    return (message as ParagraphInterface).content !== undefined;
  }
  
  
  function isMultipleChoiceData(content: any): content is MultipleChoiceData {
    return (
      content &&
      typeof content === 'object' &&
      'question' in content &&
      'options' in content &&
      'maxPoints' in content &&
      'negativePoints' in content &&
      'feedback' in content
    );
  }

    return (
        <Wrapper>
            <ContentBox ref={containerRef}>
                {date && (
                    <>
                        <TitleBox>
                            <Typography fontWeight={400} fontSize={fontSize.body} flexGrow={1}>
                                {formatDate(date)}
                            </Typography>
                            {clearProgress && chapter && (
                                <SubmitButton
                                    variant="outlined"
                                    fontColor={theme.palette.text.primary}
                                    marginTop="0"
                                    width="10rem"
                                    icon={<Loop fontSize="small" />}
                                    onClick={clearProgress}
                                >
                                    Start Over
                                </SubmitButton>
                            )}
                        </TitleBox>
                        <TitleBox sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}>
                            {chapter && (
                                <>
                                    <TitleNum fontSize={fontSize.body}>{chapter.num}</TitleNum>
                                    <TitleText fontSize={fontSize.body}>{t(chapter.title)}</TitleText>
                                    <IconButton
                                        onClick={() => bookmarkChapter && bookmarkChapter(chapter.id, chapter.isBookmarked ? 0 : 1)}
                                    >
                                        <ImageComponent
                                            src={
                                                chapter.isBookmarked
                                                    ? BookmarkFilledIcon
                                                    : theme.palette.mode === 'light'
                                                    ? BookmarkIcon
                                                    : BookmarkDarkIcon
                                            }
                                            alt="bookmark"
                                            width="1.5rem"
                                            height="1.5rem"
                                        />
                                    </IconButton>
                                </>
                            )}
                        </TitleBox>
                    </>
                )}
                    
<ChatBox padding={isHome ? '1.25rem 3rem ' : '1.25rem 0 0 0'}>

  {messagesData.map((message, index) => {
    if (isParagraphInterface(message) && message.type === 'mc') {
      const content = message.content as unknown as MultipleChoiceData;
      return (
        <ChatBubble
          key={index}
          date={message.time}
           message={ <MultipleChoice
            question={content.question}
            options={content.options}
            maxPoints={content.maxPoints}
            negativePoints={content.negativePoints}
            feedback={content.feedback}
            onChange={(selectedOptions) => console.log('Selected options:', selectedOptions)}
            isSingleChoice={content.isSingleChoice}
            onSubmit={onSubmit}

          />}
          isLeft={true}
        />
      );
    } else if (
      isParagraphInterface(message) &&  message.type === 'io' &&  [message.title === 'Multiple Choice' || message.title === 'Multiple-Choice'] ) {
        const content = message.content as unknown as MultipleChoiceData; 
        //console.log(content)
      return (
        <ChatBubble
          key={index}
          date={message.time}
          message={
            <MultipleChoice
              question={content.question}
              options={content.options}
              maxPoints={content.maxPoints}
              negativePoints={content.negativePoints}
              feedback={content.feedback}
              onChange={(selectedOptions) => console.log('Selected options:', selectedOptions)}
              isSingleChoice={content.isSingleChoice}
              onSubmit={onSubmit}

            />
          }
          isLeft={true}
          
        />
      );
    }
    else if (
      isParagraphInterface(message) &&  message.type === 'io' &&  message.title === 'GPT'
    ) {
      const jsonObject = parseHTMLToJSON(message.content);
      return (
        <ChatBubble
          key={index}
          date={message.time}
          message={jsonObject}
          isLeft={true}
        />
      );
    }
    else if (hasContent(message) && message.type === 'io' && (message.title === "Drag-and-Drop" || message.title === "Drag-and-Drop-Multiple")) {
      return (
        <ChatBubble
          key={index}
          date={message.time}
          disabled={index < messagesData.length - 2 || isLoading}
          message={<DragAndDrop
            htmlContent={message.content}
            onSubmit={onSubmit}

          />}
          isLeft={true}
          handleNext={handleNext}
        />
      );
    }
    else if (isFeedbackInterface(message) || message.type === 'io') {
    return (
      <ChatBubble
        key={index}
        date={message.time}
        disabled={index < messagesData.length - 2 || isLoading}
        message={isFeedbackInterface(message) ? message.feedback : message.content}
        isLeft={isFeedbackInterface(message) ? message.direction === '0' : true}
        outputs={!isFeedbackInterface(message) && message.outputs}
        handleNext={handleNext}
      />
    );
  }
 else if (message.type === 'next') {
      return (
        <SubmitButton
          width="7.25rem"
          height="2.5rem"
          margin="0.5rem 3.8rem"
          marginTop="0.5rem"
          disabled={index < messagesData.length - 1 || isLoading}
          onClick={() => handleNext && handleNext(message.outputs)}
        >
          Weiter
        </SubmitButton>
      );
    }else if (message.type === 'chat') {
        return (
            <ChatBubble
            key={index}
            date={message.time}
            disabled={index < messagesData.length - 2 || isLoading}
            message={isFeedbackInterface(message) ? message.feedback : message.content}
            isLeft={isFeedbackInterface(message) ? message.direction === '0' : true}
            outputs={!isFeedbackInterface(message) && message.outputs}
            handleNext={handleNext}
          />
        );
      }
      else if (message.type === 'userInput') {
        //console.log(message.type+": "+message.content)
          return (
              <ChatBubble
              key={index}
              date={message.time}
              disabled={index < messagesData.length - 2 || isLoading}
              message={message.content}
              isLeft={false}
              handleNext={handleNext}
            />
          );
        }
          else if (message.type === 'response') {
            //console.log(message.type+": "+message.content)
              return (
                  <ChatBubble
                  key={index}
                  date={message.time}
                  disabled={index < messagesData.length - 2 || isLoading}
                  message={message.content}
                  isLeft={true}
                  handleNext={handleNext}
                />
              );
    } else if (message.type === 'exit') {
      return (
        chapter?.isLastSection ? (
          // Feedback Interface anzeigen, wenn es die letzte Section ist
          <FeedbackBox>
              <FeedbackText fontSize={fontSize.title3}>
                  {t('How did you like this Microlearning?')}
              </FeedbackText>
              <FeedbackButtonBox>
                  <FeedbackButton
                      variant="contained"
                      color="success"
                      onClick={() => handleFeedback && handleFeedback(message.section_id, 1)}
                      disabled={isLoading}
                  >
                      <ImageComponent src={ThumbUpIcon} alt="send" width="2rem" height="2rem" />
                  </FeedbackButton>
                  <FeedbackButton
                      variant="contained"
                      color="error"
                      onClick={() => handleFeedback && handleFeedback(message.section_id, -1)}
                      disabled={isLoading}
                  >
                      <ImageComponent src={ThumbDownIcon} alt="send" width="2rem" height="2rem" />
                  </FeedbackButton>
                  <FeedbackButton
                      variant="contained"
                      color="secondary"
                      onClick={() => handleFeedback && handleFeedback(message.section_id, 0)}
                      disabled={isLoading}
                  >
                      {t('Skip')}
                  </FeedbackButton>
              </FeedbackButtonBox>
          </FeedbackBox>
      ) : (
          // Standardbutton anzeigen, wenn es nicht die letzte Section ist
          <SubmitButton
              width="12.5rem"
              height="2.5rem"
              margin="0.5rem 3.8rem"
              marginTop="0.5rem"
              disabled={index < messagesData.length - 1 || isLoading}
              onClick={() => handleNextChapter && handleNextChapter(message.section_id)}
          >
              Nächstes Kapitel
          </SubmitButton>
      )
  );
    }
  })}
  {isLoading && <ChatLoader isInModal={isInModal} />}
</ChatBox>


            </ContentBox>
                {typingAllowed && !typingNotAllowed && (
                <TypeBox onSubmit={handleSubmit}>
                    <TextField
                        placeholder={t('Type a message...')}
                        fullWidth
                        autoComplete="off"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        //onChange={handleChange}
                        variant="standard"
                        InputProps={{
                            sx: {
                                fontSize: fontSize.body,
                                color: theme.palette.text.primary,
                                letterSpacing: '0.02rem',
                            },
                            disableUnderline: true,
                        }}
                    />
                    <SendButton
                        variant="contained"
                        type="submit"
                        disabled={!messageText.trim()}
                    >
                        <ImageComponent src={SendIcon} alt="send" width="1.5rem" height="1.5rem" />
                    </SendButton>
                </TypeBox>
            )}
            {typingAllowed===false && !typingNotAllowed && (
                <TypeBox onSubmit={handleSubmit}>
                    <TextField
                        placeholder={t('Type a message...')}
                        fullWidth
                        autoComplete="off"
                        onChange={(e) => setMessageText(e.target.value)}
                        //onChange={handleChange}
                        variant="standard"
                        disabled={true}
                        InputProps={{
                            sx: {
                                fontSize: fontSize.body,
                                color: theme.palette.text.primary,
                                letterSpacing: '0.02rem',
                            },
                            disableUnderline: true,
                        }}
                    />
                    <SendButton
                        variant="contained"
                        type="submit"
                        disabled={true}
                    >
                        <ImageComponent src={SendIcon} alt="send" width="1.5rem" height="1.5rem" />
                    </SendButton>
                </TypeBox>
            )}
            {reportParagraph && reportAllowed && reportModalVisible && (
                <ReportModal
                    closeModal={() => {
                        setReportModalVisible(false);
                        setReportParagraphId('');
                    }}
                    handleReport={(content: string) => reportParagraph(reportParagraphId, content)}
                />
            )}
        </Wrapper>
    );
};

export default ChatComponent;