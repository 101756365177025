import React, { useState, useRef, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd/dist';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTheme, Divider } from '@mui/material';
import { drop } from 'lodash';

// Interface for DragAndDrop component props
interface DragAndDropProps {
  htmlContent: string;
  onVerify?: (result: boolean) => void;
  onSubmit: () => void;
  multiple?: boolean;
}

// Interface for DropZone
interface DropZone {
  id: string; // Unique ID for the drop zone
  label: string; // Label displayed above the drop zone
  multiple: boolean; // Flag to allow multiple items in a drop zone
}


const DragAndDrop: React.FC<DragAndDropProps> = ({ htmlContent, onVerify, onSubmit, multiple }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  const parser = new DOMParser(); 
  const doc = parser.parseFromString(htmlContent, 'text/html'); 


  const dragElements = Array.from(doc.querySelectorAll('.diadibi-drag-element')).map((el) => ({
    id: el.id, 
    text: el.textContent?.trim() || '', 
  }));


  const dropZones: DropZone[] = Array.from(doc.querySelectorAll('.diadibi-drop-zone')).map((el) => ({
    id: el.id, 
    label: el.previousElementSibling?.textContent?.trim() || '', // Label above the drop zone
    multiple: !!Array.from(doc.querySelectorAll(`.diadibi-drag-element[correct-zone="${el.id}"]`)).length, // Check if multiple items can be dropped here
  }));

  // State for zones: which drag elements are assigned to each drop zone
  const [zones, setZones] = useState<Record<string, string[]>>(
    Object.fromEntries(dropZones.map((zone) => [zone.id, []]))  // Allow multiple elements per drop zone
  );

  // State to track which drag elements are draggable
  const [dragZone, setDragZone] = useState<Record<string, boolean>>(
    Object.fromEntries(dragElements.map((el) => [el.id, true]))  // Initially, all drag elements are draggable
  );

  // State to track feedback for each drop zone (correct/incorrect)
  const [feedback, setFeedback] = useState<Record<string, 'correct' | 'incorrect' | null>>(
    Object.fromEntries(dropZones.map((zone) => [zone.id, null])) // Initialize feedback as null
  );

  // Points state to store the current score
  const [points, setPoints] = useState<number>(0);
  // State to track whether verification has been clicked
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  // State to track whether verification is complete (to disable dragging)
  const [isVerified, setIsVerified] = useState<boolean>(false);

  // Move element to a drop zone
  const moveElementToZone = (itemId: string, zoneId: string) => {
    if (isVerified) return; // Prevent dragging after verification

    const newZones = { ...zones }; // Copy current zones state
    const currentZone = newZones[zoneId]; // Get the current items in the drop zone

    // Find the corresponding drop zone to check if 'multiple' is true
    const zone = dropZones.find((zone) => zone.id === zoneId);

    // If multiple is true, add the element to the zone without removing others
    if (zone?.multiple) {
      newZones[zoneId] = [...currentZone, itemId]; // Add item to the zone
    } else {
      // If the zone already has an element, replace it
      if (currentZone.length > 0) {
        const replacedItemId = currentZone[0]; // Get the replaced element ID
        newZones[zoneId] = [itemId]; // Replace the element

        // Return the replaced element back to the drag zone
        setDragZone((prev) => ({ ...prev, [replacedItemId]: true }));
      } else {
        // If the zone is empty, simply add the element
        newZones[zoneId] = [...currentZone, itemId];
      }
    }

    // Remove the element from all other zones
    for (let otherZoneId in newZones) {
      if (otherZoneId !== zoneId) {
        newZones[otherZoneId] = newZones[otherZoneId].filter((id) => id !== itemId); // Remove item from all other drop zones
      }
    }

    setZones(newZones); // Update the zones with new data
    setDragZone((prev) => ({ ...prev, [itemId]: false })); // Mark the element as not draggable
    setFeedback((prev) => ({ ...prev, [zoneId]: null })); // Reset feedback for the zone
  };

  const handleVerify = () => {
    setHasVerified(true);
    setIsVerified(true); // Disable dragging after verification

    let score = 0; // Start with 0 points

    dropZones.forEach((zone) => {
      // Get the correct items for this zone based on the 'correct-zone' attribute
      const correctItems = Array.from(
        doc.querySelectorAll(`.diadibi-drag-element[correct-zone="${zone.id}"]`)
      ).map((el) => el.id);

      const itemsInZone = zones[zone.id]; // The items that are in this zone

      if (zone.multiple) {
        // For multiple zones: all correct elements must be dropped and no wrong elements
        const correctCount = itemsInZone.filter((itemId) => correctItems.includes(itemId)).length;
        const incorrectCount = itemsInZone.filter((itemId) => !correctItems.includes(itemId)).length;

        // Check if all correct elements are dropped and no wrong elements
        if (correctCount === correctItems.length && incorrectCount === 0) {
          setFeedback((prev) => ({ ...prev, [zone.id]: 'correct' }));
          score += 1; // +1 point for a correct drop zone
        } else {
          setFeedback((prev) => ({ ...prev, [zone.id]: 'incorrect' }));
          score -= 1; // -1 point for incorrect elements or incomplete drops
        }
      } else {
        // For single zone: just check if the correct element is dropped
        const itemsInZone = zones[zone.id];
        if (itemsInZone.length === 0) {
          // If no items are in the zone, mark it incorrect
          setFeedback((prev) => ({ ...prev, [zone.id]: 'incorrect' }));
          score -= 1;
        } else {
          if(JSON.stringify(itemsInZone) === JSON.stringify([zone.id]))
            {
          setFeedback((prev) => ({ ...prev, [zone.id]: 'correct' }));
          score += 1;
        }
          if (JSON.stringify(itemsInZone) !== JSON.stringify([zone.id])) {
             setFeedback((prev) => ({ ...prev, [zone.id]: 'incorrect' }));
             score -= 1;
        }


    }
    
    }});

    // Ensure points can't go negative
    setPoints(Math.max(0, score));

    if (onVerify) {
      onVerify(score >= dropZones.length); // Check if all zones are correctly filled
    }
    setIsSubmitted(true)
    onSubmit();
  };
  
  // Drag element component
  const DragElement = ({ id, text }: { id: string; text: string }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'ELEMENT',
      item: { id },
      canDrag: !isVerified,  // Disable dragging if verification has been done
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.7 : 1,
          padding: '1rem',
          margin: '0.5rem',
          backgroundColor: 'rgb(161, 183, 165)',
          color: '#ffffff',
          borderRadius: '2px',
          boxShadow: theme.shadows[1],
          cursor: 'grab',
          textAlign: 'center',
          fontWeight: 500,
          fontSize: '0.9rem',
          transition: 'transform 0.2s ease',
          touchAction: 'none',
        }}
      >
        {text}
      </div>
    );
  };

  // Drop zone component
  const DropZone = ({ id, label }: DropZone) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'ELEMENT',
      drop: (item: { id: string }) => moveElementToZone(item.id, id),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
      }),
    });

    const backgroundColor =
      feedback[id] === 'correct'
        ? 'rgba(161, 183, 165, 0.4)' // Green for correct
        : feedback[id] === 'incorrect'
          ? '#FFCDD2' // Red for incorrect
          : isOver
            ? 'rgba(161, 183, 165, 0.2)'
            : '#FAFAFA'; // Default background

    return (
      <div
        ref={drop}
        style={{
          position: 'relative',
          width: '150px',
          minHeight: '150px',
          backgroundColor,
          border: `1px solid rgb(161, 183, 165)`,
          borderRadius: '2px',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflow: 'auto',
          touchAction: 'none',
          padding: '10px',
        }}
      >
        {/* Label */}
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            padding: '0.25rem 0',
            fontSize: '0.85rem',
            fontWeight: 500,
            borderBottom: `1px solid rgb(161, 183, 165)`,
            marginBottom: '10px',
          }}
        >
          {label}
        </div>

        {/* Dragged Element(s) */}
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflowY: 'auto',
          }}
        >
          {zones[id].map((itemId) => (
            <DragElement
              key={itemId}
              id={itemId}
              text={dragElements.find((el) => el.id === itemId)?.text || ''}
            />
          ))}
        </div>
      </div>
    );
  };

/*const titleText = doc.body.firstChild
? doc.body.firstChild.textContent?.replace(/@user\.[a-zA-Z]+/g, '').trim()
: '';*/
const titleText = doc.body.innerHTML.split('<drag-drop>')[0]?.trim();



  return (
    <DndProvider backend={HTML5Backend}>
      <div
        ref={containerRef}
        style={{
          padding: '1.5rem',
          maxWidth: '700px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
          backgroundColor: theme.palette.background.default,
          borderRadius: '2px',
          boxShadow: 'none',
          border: 'none',
        }}
      >
        {/* Display the title text if it exists */}
        {titleText && (
          <h3
            style={{
              marginBottom: '1rem',
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: titleText }} />
          </h3>
        )}

        {/* Drop-Zonen */}
        <div
          style={{
            padding: '0.5rem',
            backgroundColor: 'rgba(161, 183, 165, 0.1)',
            borderRadius: '2px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
            justifyContent: 'center',
            width: '100%',
            overflow: 'auto',
          }}
        >
          {dropZones.map(({ id, label, multiple }) => (
            <DropZone key={id} id={id} label={label} multiple = {multiple}/>
          ))}
        </div>

        <Divider style={{ width: '100%', backgroundColor: theme.palette.grey[300] }} />

        {/* Drag-Elemente */}
        <div
          style={{
            padding: '1rem',
            backgroundColor: 'rgba(161, 183, 165, 0.1)',
            borderRadius: '2px',
            width: '100%',
            maxWidth: '700px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.75rem',
            justifyContent: 'center',
            overflow: 'auto',
          }}
        >
          {dragElements
            .filter(({ id }) => dragZone[id])
            .map(({ id, text }) => (
              <DragElement key={id} id={id} text={text} />
            ))}
        </div>

        {/* Show points if "Überprüfen" is clicked */}
        {hasVerified ? (
          <p
            style={{
              padding: '0.5rem 1.5rem',
              color: theme.palette.text.primary,
              fontWeight: 500,
              fontSize: '1rem',
              textAlign: 'center',
            }}
          >
            Erreichte Punkte: {points} von {dropZones.length}
          </p>
        ) : (
          <button
            onClick={handleVerify}
            style={{
              padding: '0.5rem 1.5rem',
              backgroundColor: 'rgb(161, 183, 165)',
              color: '#FFFFFF',
              border: 'none',
              borderRadius: '0.5rem',
              cursor: 'pointer',
              fontWeight: 500,
              fontSize: '0.85rem',
              boxShadow: theme.shadows[1],
              transition: 'background-color 0.2s ease',
            }}
          >
            Überprüfen
          </button>
        )}
      </div>
    </DndProvider>
  );
};

export default DragAndDrop;
